// pages/.jsx
import { useCallback, useEffect, useState } from "react";
// importing the supabase helper
import { supabase } from "./../supabaseClient";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AnimatedNumber from "react-animated-numbers";

// import styles
import Styles from "./../main.module.css";
import rhino from './../img/rhino.png';
import { User } from "@supabase/supabase-js";
import Waiver from "./waiver";
import { Form, Spinner } from "react-bootstrap";

import ConfettiExplosion from 'react-confetti-explosion';

function ordinal_suffix_of(i: number) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return "st";
    }
    if (j === 2 && k !== 12) {
        return "nd";
    }
    if (j === 3 && k !== 13) {
        return "rd";
    }
    return "th";
}

export default function Checkin() {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [checkedIn, setCheckedIn] = useState(false);
  const [checkInCount, setcheckInCount] = useState(0);
  const [user, setUser] = useState<User|null>(null);
  const [waiverSigned, setWaiverSigned] = useState(false);
  const [shouldShowConfetti, setShouldShowConfetti] = useState(false);

  const queryParams = new URLSearchParams(window.location.search)
  const checkin_date = queryParams.get("date")
  const checkin_ts = queryParams.get("ts")
  const checkin_sig = queryParams.get("sig")

  useEffect(() => {

    async function fetchUser() {
        const { data: { user } } = await supabase.auth.getUser();
        setUser(user);

        if(user){
            if(!!user.user_metadata.waiver_signed){
                setWaiverSigned(true);




                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        date: checkin_date,
                        ts: checkin_ts,
                        sig: checkin_sig
                    })
                };

                // // verify signature
                fetch('https://mp4cx7gxduuujofdrfvsb4mgxa0wktmg.lambda-url.us-east-1.on.aws/', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if(data.is_valid) {
                            const doCheckin = async () => {
                                // lets record the checkin    
                                const { data, error } = await supabase
                                .from('Checkins')
                                .upsert({ 
                                    runner_id: user.id, 
                                    checkin_date: checkin_date,
                                    location: 'taproom'
                                })
                                .select();

                                const { count } = await supabase
                                .from('Checkins')
                                .select('*', { count: 'exact', head: true }).eq('runner_id', user.id)

                                if(count){
                                    setcheckInCount(count);
                                    setCheckedIn(true);
                                    setShouldShowConfetti(true);
                                    setTimeout(() => {
                                        setShouldShowConfetti(false);
                                        
                                    }, 3000);
                                    
                                }else{
                                    setError("Sorry there was an error checking in")
                                }
                                
                                

                            }   
                            doCheckin();
                            

                        } else {
                            setError("Invalid checkin link. Please try scanning code again");
                        }
                    });

            }
        }

        setLoading(false);
    }

    fetchUser();
  }, []);


  const signWaiver = useCallback(async () => {
    if(!name) {
        alert("Please fill in your name");
        return;
    }
    setLoading(true);
    const { data, error } = await supabase.auth.updateUser({
        data: {
            waiver_signed: new Date().toUTCString(),
            display_name: name
        }
    });
    await supabase
    .from('profiles')
    .update({ 
        username: name
    }).eq('id', user?.id);
    setLoading(false);
    if (error) {
        alert(error);
    }else{
        window.location.reload();
    }
  }, [name]);


  if(loading) {
    return <></>
  }

  if(waiverSigned == false) {
    return <>
        <Waiver />

        <Row className="justify-content-center">
            <Col>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label htmlFor="inputPassword5">Full Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Form.Text id="passwordHelpBlock" muted>
                            By providing your name you agree to the terms above.
                        </Form.Text>
                    </Form.Group>

                    <Button variant="primary" onClick={signWaiver}>Accept</Button>
                    <br />
                    <br />
                    <br />
                    <hr />
                </Form>
            </Col>
        </Row>
    </>
  }

  if(checkedIn == true) {
    return <>
        {shouldShowConfetti &&  <div style={{marginLeft: '50%'}}> <ConfettiExplosion colors={['#926de0', '#34AB3A', "#666666", "#343434"]} /></div>}
        <Row className="justify-content-center">
            <Row>
                
                <div style={{textAlign: 'center', width: '100%', marginTop: '10%'}}>
                    <img src={rhino} style={{maxWidth: '200px'}} />
                </div>
            
            </Row>
            <Row>
                <h2 className="text-center">{user?.user_metadata.display_name}</h2>
                <h3 className="text-center">You're checked in for your:</h3>
                <h1 className="text-center animated-number-wrapper">
                <AnimatedNumber
                    className="animated-number"
                    animateToNumber={checkInCount}
                    transitions={(index) => ({
                        type: "ease-in",
                        duration: 1.5,
                      })}
                    />{ordinal_suffix_of(checkInCount)} 
                 </h1>
                 <h3 className="text-center">run with the rhinos</h3>
            </Row>
        </Row>
    </>
  }

  if(!!error) {
    return <>
        <Row className="justify-content-center">
            <Row>
                
                <div style={{textAlign: 'center', width: '100%'}}>
                <img src={rhino} style={{maxWidth: '200px'}} />
                </div>
            
            </Row>
            <Row>
                <h1 className="text-center">{error}</h1>
                <h3 className="text-center">Please try again</h3>
            </Row>
        </Row>
    </>
  }

  return (
        <Row className="justify-content-center" style={{padding:'100px'}}>
            <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
            </Spinner>
        </Row>
  );
}