// pages/.jsx
import { useCallback, useEffect, useState } from "react";
import Styles from "./../main.module.css";
import rhino from './../img/rhino.png';
import { Col, Row } from "react-bootstrap";



export default function Waiver() {
  return (<>
        <Row>
            
                <div style={{textAlign: 'center', width: '100%'}}>
                <img src={rhino} style={{maxWidth: '200px'}} />
                </div>
            
        </Row>
        <Row>
            <h2 style={{textAlign: 'center'}}>RHINO RUN CLUB WAIVER</h2>
        </Row>
        <Row>
            <Col style={{padding: '15px'}}>
                <p>
                I understand that runs with the Rhino Run Club may be physically strenuous and I voluntarily participate in them with full knowledge that there is risk of personal injury, property loss or death. I agree that Old Planters and the Rhino Run Club are in no way responsible for the safekeeping of my personal belongings while I attend runs. I agree that neither I, my heirs, assigns or legal representatives will sue or make any other claims of any kind whatsoever against Old Planters, the Rhino Run Club or its members, instructors, agents, representatives, or sponsors for any personal injury, property damage/loss, or wrongful death, whether caused by negligence or otherwise.
                </p>
                <p>
                Rhino Run Club staff or ambassadors may take pictures and video of runners during runs and gatherings to be used promotionally and for social media purposes.
                </p>
                <p>
                By signing below I agree that I have read and understood the terms of this assumption of risk, release and waiver of liability, and indemnity agreement and agree to its terms
                </p>
            </Col>
        </Row>
    </>
  );
}