// pages/Auth.jsx
import { useState } from "react";
// importing the supabase helper
import { supabase } from "./../supabaseClient";
// import styles
import Styles from "./../main.module.css";
export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  // function to handle the login and signup
  const handleAuthentication = async (email: string) => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOtp({ email, options: {
            shouldCreateUser: true,
            emailRedirectTo: window.location.href,
        }, 
      },
        
      );
      if (error) throw error;
      setEmailSent(true)
    } catch (error: any) {
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  if (emailSent) {
    return (
        <div className={Styles.Container}>
        <div className={Styles.FormWrap}>
            <h1 className={Styles.Heading}><img src="https://rhinorun-cdn.s3.amazonaws.com/rhino.png" height="50px" /> Rhino Run Checkin</h1>
            <p className={Styles.Description}>
                Check your email for your link <br />
                {email}
            </p>
        </div>
        </div>
    );
  }

  return (
    <div className={Styles.Container}>
      <div className={Styles.FormWrap}>
        <h1 className={Styles.Heading}><img src="https://rhinorun-cdn.s3.amazonaws.com/rhino.png" height="50px" /> Rhino Run Checkin</h1>
        <p className={Styles.Description}>
          Enter your email to sign in
        </p>
        <div className={Styles.InputWrapper}>
          <input
            className={Styles.InputField}
            type='email'
            placeholder='john.doe@email.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={Styles.InputWrapper}>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleAuthentication(email);
            }}
            className={Styles.Button}
            disabled={loading}
          >
            {loading ? <span>Loading</span> : <span>Send checkin link</span>}
          </button>
        </div>
      </div>
    </div>
  );
}