import React from 'react';
import AuthPage from './pages/auth';
import Checkin from './pages/checkin';
import logo from './logo.svg';
import './App.css';



import { useState, useEffect } from 'react'
import { createClient, Session } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'

import { supabase } from "./supabaseClient";
import { Container } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

export function App() {
  const [session, setSession] = useState<Session|null>(null)

  useEffect(() => {

    if(window.location.hostname != 'localhost' && window.location.pathname == '/'){
      window.location.href = "https://www.oldplanters.com/rhino-run-club/"
    }

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (!session) {
    return (<AuthPage />)
  }
  else {
    return (<Container><Checkin /></Container>)
  }
}

export default App;
